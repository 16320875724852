import React from 'react'
import { ThemeProvider } from 'styled-components'
import { Box, Flex, Text, Heading, Link } from 'rebass/styled-components'
import { slate as slateTheme } from '../theme'
import { Grid, GridChild } from './GridLayout'
import { Fade } from 'react-reveal'
import Instagram from '../components/icons/Instagram.svg'
import Dribbble from '../components/icons/Dribbble.svg'

const Footer = props => (
  <ThemeProvider theme={slateTheme}>
    <Grid
      bg='bgColor'
      minHeight='50vh'
      pt={8}
      pb={3}
      sx={{
        gridTemplateRows: `repeat(2, minmax(0, 1fr)) 0fr`
      }}
    >
      <GridChild colStart={1} colEnd={[3, 7]} rowStart={[1]} rowEnd={[2]}>
        <Fade delay={200} bottom>
          <div>
            <Heading variant='h1'>Get in touch with us.</Heading>
            <Heading variant='h3'>
              <Link href='mailto:hi@forge.coop'>hi@forge.coop</Link>
            </Heading>
          </div>
        </Fade>
      </GridChild>
      <GridChild colStart={1} colEnd={[3, 7]} rowStart={[3]} rowEnd={[4]}>
        <Flex alignItems='center'>
          <Link
            mr={1}
            href='https://www.instagram.com/designedbyforge/'
            target='_blank'
          >
            <Instagram width={2} height={2} />
          </Link>
          <Link href='https://dribbble.com/forgenyc' target='_blank'>
            <Dribbble width={2} height={2} />
          </Link>
        </Flex>
      </GridChild>
    </Grid>
  </ThemeProvider>
)

export default Footer
