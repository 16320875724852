import preset from '@rebass/preset'
import shevy from './type'
import { defaultsDeep } from 'lodash'

export const breakpoints = ['40em', '52em', '70em']

console.log(shevy)

const heading = {
  fontWeight: 'heading'
  // fontSize: [4, 5, 6],
  // mb: [2, 3]
}

const COLORS = {
  midnight: '#362632',
  beige: '#FFF4E9',
  clay: '#916A6B',
  orange: '#F47252',
  secondaryOrange: '#F68E58',
  peach: '#FDDFD7',
  white: '#ffffff',
  black: '#0F0F1C',
  slate: '#4C4759',
  yellow: '#F68E58',
  brown: '#380C06',
  maroon: '#933021',
  mauve: '#916A6B'
}

const theme = {
  ...preset,
  colors: {
    ...COLORS,
    textColor: COLORS.black,
    bgColor: COLORS.beige
  },
  fonts: {
    body: 'Sonny Gothic',
    heading: 'Sonny Gothic',
    button: 'Sonny Gothic'
  },
  fontWeights: {
    body: 200,
    heading: 400,
    bold: 400
  },
  fontSizes: [
    shevy.body.fontSize,
    shevy.h5.fontSize,
    shevy.h4.fontSize,
    shevy.h3.fontSize,
    shevy.h2.fontSize,
    shevy.h1.fontSize
  ],
  lineHeights: {
    body: 1.6,
    heading: 1.2
  },
  space: [
    0,
    '1rem',
    '2rem',
    '3rem',
    '4rem',
    '5rem',
    '6rem',
    '7rem',
    '8rem',
    '9rem',
    '10rem',
    '11rem',
    '12rem'
  ],
  sizes: [
    0,
    '1rem',
    '2rem',
    '3rem',
    '4rem',
    '5rem',
    '6rem',
    '7rem',
    '8rem',
    '9rem',
    '10rem',
    '11rem',
    '12rem'
  ],

  // Overrides
  breakpoints,
  useCustomProperties: true,

  variants: {
    link: {
      color: 'orange',
      '&:hover, &:visited': {
        color: 'orange'
      }
    },
    container: {
      maxWidth: [breakpoints[0], breakpoints[1]],
      mx: 'auto',
      px: ['1.5rem', 2, 3]
    },
    grid: {
      display: 'grid',
      gridGap: [1, 1]
    }
    /* projectContentBlock: {
      marginBottom: [1, 2]
    } */
  },
  text: {
    default: {
      ...shevy.content,
      color: 'textColor'
    },
    heading: {
      ...heading
    },
    h1: {
      ...shevy.h1,
      color: 'textColor'
    },
    h2: {
      ...shevy.h2,
      color: 'textColor'
    },
    h3: {
      ...shevy.h3,
      color: 'textColor'
    },
    h4: {
      ...shevy.h4,
      color: 'textColor'
    },
    h5: {
      ...shevy.h5,
      color: 'textColor'
    },
    h6: {
      ...shevy.h6,
      color: 'textColor'
    },
    p: {
      ...shevy.content,
      color: 'textColor'
    },
    content: {
      fontSize: shevy.content.fontSize,
      lineHeight: shevy.content.lineHeight
    },
    accent: {
      color: 'mauve',
      // fontSize: '.625rem',
      // lineHeight: '1.0625rem',
      // letterSpacing: '.1875rem',
      fontSize: '10px',
      letterSpacing: '3px',
      lineHeight: '17px',
      textTransform: 'uppercase'
    }
  },
  buttons: {
    primary: {
      lineHeight: 1,
      transition: 'background-color 0.3s ease-out, color 0.3s ease-out',
      color: 'beige',
      bg: 'midnight',
      fontFamily: 'button',
      fontWeight: 'body',
      borderRadius: '5rem',
      cursor: 'pointer'
    },
    nav: {
      bg: 'slate',
      color: 'beige',
      px: '1.5rem',
      height: ['40px', '60px'],
      display: 'flex',
      alignItems: 'center'
    }
  },
  styles: {
    root: {
      color: 'textColor',
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
      fontSize: '1rem'
    }
  }
}

export const orange = defaultsDeep(
  {
    colors: {
      textColor: COLORS.brown,
      bgColor: COLORS.orange
    }
  },
  theme
)

export const dark = defaultsDeep(
  {
    colors: {
      textColor: COLORS.beige,
      bgColor: COLORS.black
    }
  },
  theme
)

export const slate = defaultsDeep(
  {
    colors: {
      textColor: COLORS.peach,
      bgColor: COLORS.midnight
    }
  },
  theme
)

export default theme
