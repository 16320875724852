import Shevy from 'shevyjs'

const shevy = new Shevy({
  baseFontSize: '1rem',
  baseLineHeight: 1.4,
  baseFontScale: 'majorThird',
  addMarginBottom: true,
  proximity: true,
  proximityFactor: 0.5
})

export default shevy
