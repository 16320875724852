import React from 'react'
import styled, { css } from 'styled-components'
import breakpoint from '../lib/breakpoint'
import { isArray } from 'lodash'
import MediaQuery, { useMediaQuery } from 'react-responsive'
import { Box } from 'rebass/styled-components'

// Default breakpoints from https://github.com/jameslnewell/styled-components-breakpoint
export const BREAKPOINT = {
  MOBILE: 0, // targeting all devices
  TABLET: 737, // targeting devices that are larger than the iPhone 6 Plus (which is 736px in landscape mode)
  DESKTOP: 1025 // targeting devices that are larger than the iPad (which is 1024px in landscape mode)
}

export const OnlyMobile = ({ children }) => {
  const isMobile = useMediaQuery({
    maxDeviceWidth: BREAKPOINT.TABLET
  })
  return isMobile ? children : null
}

export const HideMobile = ({ children }) => {
  const isntMobile = useMediaQuery({
    minDeviceWidth: BREAKPOINT.TABLET
  })
  return isntMobile ? children : null
}

const DEFAULT_OFFSET = 4
export const OffsetWrapper = styled.div`
  ${props => css`
    width: calc(100% + ${(props.offset || DEFAULT_OFFSET) * 2}rem);
    height: calc(100% + ${(props.offset || DEFAULT_OFFSET) * 2}rem);
    margin-left: -${props.offset || DEFAULT_OFFSET}rem;
    margin-right: -${props.offset || DEFAULT_OFFSET}rem;
    margin-top: -${props.offset || DEFAULT_OFFSET}rem;
    margin-bottom: -${props.offset || DEFAULT_OFFSET}rem;
  `}

  ${breakpoint('tablet')`
    width: 100%;
    height: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 0;
  `}
`

export const GridChild = ({ sx, ...props }) => (
  <Box
    sx={{
      gridColumnStart: props.colStart,
      gridColumnEnd: props.colEnd,
      gridRowStart: props.rowStart,
      gridRowEnd: props.rowEnd,
      ...(sx || {})
    }}
    {...props}
  />
)

export const Grid = props => {
  const { sx, ...otherProps } = props
  return (
    <Box
      variant='styles.root'
      minHeight='93vh'
      px={[2, 4, 9]}
      pt={[8, 4, 7]}
      pb={[8, 4, 7]}
      overflow='hidden'
      sx={{
        display: 'grid',
        gridAutoColumns: '49%',
        gridTemplateRows: [
          'repeat(6, minmax(0, 1fr))',
          'repeat(4, minmax(0, 1fr))'
        ],
        gridRowGap: [1, 2],
        gridColumnGap: '2%',
        gridTemplateColumns: ['none', 'repeat(6,1fr)'],
        ...sx
      }}
      {...otherProps}
    />
  )
}
