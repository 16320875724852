import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import Link from 'gatsby-link'
import { createGlobalStyle, ThemeProvider } from 'styled-components'
import theme from '../theme'
import { Box } from 'rebass/styled-components'
import Navigation from './Navigation'
import Footer from './Footer'
import '../styles/fonts.css'

const GlobalStyle = createGlobalStyle`
  html {
    margin: 0;
    background-color: ${theme.colors.white};
    font-size: 16px;
  }

  body {
    margin: 0;
  }

  @media (min-width: 1000px) {
    html {
      font-size: 18px;
    }
  }

  @media (min-width: 1440px) {
    html {
      font-size: 22px;
    }
  }

  @media (min-width: 1880px) {
    html {
      font-size: 26px;
    }
  }

  @media (min-width: 2200px) {
    html {
      font-size: 30px;
    }
  }

`

class Template extends React.Component {
  render () {
    const { children } = this.props
    return (
      <ThemeProvider theme={theme}>
        <Helmet title={'Forge'}>
          <link rel='stylesheet' type='text/css' href='/normalize.css' />
        </Helmet>
        <GlobalStyle />
        <Box variant='styles.root'>
          <Navigation />
          {children}
          <Footer />
        </Box>
      </ThemeProvider>
    )
  }
}

Template.propTypes = {
  // children: PropTypes.func,
  location: PropTypes.object,
  route: PropTypes.object
}

export default Template
