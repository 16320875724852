import React, { useState } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { Box, Flex, Button } from 'rebass/styled-components'
import Logo from './icons/Logo.svg'
import { Link } from 'gatsby'
import SideMenu from './SideMenu'
import { dark } from '../theme'

const Navigation = props => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  return (
    <ThemeProvider theme={dark}>
      <Flex
        px={[2, 4, 9]}
        py='.65rem'
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          zIndex: 999,
          justifyContent: 'space-between',
          alignItems: 'center',
          bg: 'bgColor',
          boxShadow: '0px 0px 5px rgba(0, 0, 0, .25)'
        }}
      >
        <Box>
          <Link to='/'>
            <Flex width='2.5rem' height='2.5rem' alignItems='center'>
              <Logo width='100%' height='100%' />
            </Flex>
          </Link>
        </Box>
        <Button
          onClick={() => setIsMenuOpen(true)}
          sx={{
            bg: 'slate',
            display: 'flex',
            alignItems: 'center',
            fontSize: '.8rem',
            py: '.75rem',
            px: '1.25rem',
            '&:hover': {
              bg: 'mauve'
            },
            '&:after': {
              content: [`'Contact'`, `'Contact Us'`]
            }
          }}
        >
          {' '}
        </Button>

        <SideMenu open={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
      </Flex>
    </ThemeProvider>
  )
}

export default Navigation
