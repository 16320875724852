import React, { useEffect, useRef } from 'react'
import { Box, Flex, Heading, Text, Link } from 'rebass/styled-components'
import styled, { ThemeProvider } from 'styled-components'
import { slate as theme } from '../theme'
import X from './icons/X.svg'
import { Fade } from 'react-reveal'

const useClickOutside = (ref, callback) => {
  const handleClick = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback()
    }
  }
  React.useEffect(() => {
    if (callback) {
      document.addEventListener('click', handleClick)
    }
    return () => {
      document.removeEventListener('click', handleClick)
    }
  })
}

const SideMenu = ({ open, onClose }) => {
  const clickRef = React.useRef()
  useClickOutside(clickRef, open ? onClose : null)
  return (
    <StyledSideMenu open={open}>
      <ThemeProvider theme={theme}>
        <Flex
          ref={clickRef}
          bg='bgColor'
          height='100%'
          width='100%'
          flexDirection='column'
          px={2}
          py={4}
        >
          <Box
            height='1.5rem'
            width='1.5rem'
            sx={{ cursor: 'pointer' }}
            onClick={onClose}
          >
            <X height='100%' width='100%' />
          </Box>
          <Fade duration={700} delay={100} right when={open} appear={open}>
            <Heading variant='h3' mt={5} color='textColor'>
              Email us at <Link href='mailto:hi@forge.coop'>hi@forge.coop</Link>{' '}
              and we'll get back to you within 24 hours.
            </Heading>
          </Fade>
        </Flex>
      </ThemeProvider>
    </StyledSideMenu>
  )
}

export default SideMenu

const StyledSideMenu = styled.div`
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(100%)')};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  box-shadow: ${({ open }) =>
    open ? `0px 0px 5px rgba(0, 0, 0, 0.25)` : `none`};
  transition: transform 400ms ease-in-out;
  width: 450px;
  @media (max-width: 450px) {
    width: 100%;
  }
`
